import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { ColorPalette } from "../StylingConstants";
import { Fonts, FontColors } from "../Typography";

const StyledTab = styled(Fonts.Body1theme50)`
  line-height: 14px;
  ${props => (props.$active ? FontColors.theme100 : FontColors.theme50)}
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 42px;
  white-space: nowrap;

  ${props =>
    props.$active
      ? `
    border-bottom: 2px solid ${props.theme.colors.branding400};
    `
      : css`
          border-bottom: 2px solid transparent;
          &:hover {
            ${FontColors.theme80}
          }
        `}

  & + & {
    margin-left: 32px;
  }
`;

const CountIndicator = styled(Fonts.Caption2theme50)`
  background: ${ColorPalette.white15};
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 12px;
  margin-left: 8px;
  height: 16px;
  padding: 2px 6px;
`;

/**
 * Yuka v2.0
 * This renders a clickable tab to switch between active tabs within a layout.
 * These styles are aligned with ``NavLink``
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const TabItem = props => (
  <StyledTab $active={props.selected} onClick={props.handleTabClick} role="tab">
    {props.label}
    {(props.count || props.count === 0) && <CountIndicator>{props.count}</CountIndicator>}
  </StyledTab>
);

TabItem.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  count: PropTypes.node,
  handleTabClick: PropTypes.func.isRequired,
};

TabItem.defaultProps = {
  selected: false,
};

TabItem.displayName = "TabItem";

export default TabItem;
