import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import getLinkProps, { LinkTypes } from "./getLinkProps";

import { body1 } from "../Typography";
import { ColorPalette } from "../StylingConstants";
import { useYukaTheme } from "../ThemeContext";
import { ButtonStyles } from "./constants";

const StyledButton = styled.button.attrs({ className: "btn" })`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  min-width: 0;
  outline: none;
  transition: all 0.1s cubic-bezier(0.33, 0, 0.2, 1);

  // 1  - Container
  ${props => {
    if (Object.keys(ButtonStyles).includes(props.$buttonStyle)) {
      return props.theme.button[props.$buttonStyle].container;
    }
    return props.theme.button[ButtonStyles.OUTLINE].container;
  }}

  // 2 - Button Text
  ${body1};
  line-height: 16px;

  // 3 - Leading/Trailing Icon
  // height, width, color set as props
  svg {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  // Conditional Styles from props.$buttonStyle and $selected
  ${props => {
    // 4 - Disabled
    if (props.disabled) {
      if (Object.keys(ButtonStyles).includes(props.$buttonStyle)) {
        return props.theme.button[props.$buttonStyle].disabled;
      }
      return props.theme.button[ButtonStyles.CTA].disabled;
    } else {
      if (Object.keys(ButtonStyles).includes(props.$buttonStyle)) {
        // A button cannot be simultaneously selected and disabled.
        if (props.$selected && props.$buttonStyle === ButtonStyles.RAISED) {
          // TODO we need to implement selected state for the remaining ButtonStyles.
          return css`
            ${props.theme.button[props.$buttonStyle].selectedDefault}

            &&:hover {
              ${props.theme.button[props.$buttonStyle].selectedHover}
            }

            &&:active {
              ${props.theme.button[props.$buttonStyle].selectedActive}
            }
          `;
        }
        return css`
          ${props.theme.button[props.$buttonStyle].default}

          &&:hover {
            ${props.theme.button[props.$buttonStyle].hover}
          }

          &&:active {
            ${props.theme.button[props.$buttonStyle].active}
          }
        `;
      } else {
        return css`
          ${props.theme.button[ButtonStyles.OUTLINE].default}

          &&:hover {
            ${props.theme.button[ButtonStyles.OUTLINE].hover}
          }

          &&:active {
            ${props.theme.button[ButtonStyles.OUTLINE].active}
          }
        `;
      }
      // TODO remember the props.$selected styles for key action buttons. Probably should
      // standardize selected support, with `selectedHover`, `selectedActive`.
      /*
      css`
        background: ${ColorPalette.blue200};
        color: ${ColorPalette.blue500};
        border: none;

        &&:hover {
          background: ${ColorPalette.blue300};
        }

        &&:active {
          background: ${ColorPalette.blue300};
          color: ${ColorPalette.blue700};

          svg path {
            fill: ${ColorPalette.blue700};
          }
        }
      `
      */
    }
  }}

  // Apply selector using class name instead of & because styled components acts weird
  .icon-btn + &,
  .btn + & {
    // rows of buttons are spaced out
    margin-left: 12px;
  }
`;

const computeButtonIconColor = (yukaTheme, buttonStyle, disabled, selected) => {
  if (disabled) {
    return ColorPalette.white15;
  }
  if (buttonStyle === ButtonStyles.CTA) {
    return ColorPalette.black50;
  }
  if (buttonStyle === ButtonStyles.RAISED && selected) {
    return yukaTheme.colors.branding500;
  }
  return ColorPalette.white50;
};

/**
 * Renders an element that looks like a button to the screen. Underlying HTML element depends on
 * function.
 *
 * url - This prop is a string that is a valid href or a string/object that react-router can parse.
 *   This results in either an `<a>` tag or a `<Link>` being rendered.
 *
 * Either url, to, or href must be specified for `target`, `rel`, or `download` attributes to be applied
 *
 * onClick - This prop is a function handler that results in an html `<button>` being rendered if
 *   none of the above are specified. Must be set for `type` to be applied.
 *
 * buttonStyle - If this prop is supplied, then we render a filled button with the given fill style.
 *   If not supplied then we render an outline button.
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 */
const Button = React.forwardRef((props, ref) => {
  const yukaTheme = useYukaTheme();
  const iconColor = computeButtonIconColor(
    yukaTheme,
    props.buttonStyle,
    props.disabled,
    props.selected
  );

  const legacyProps = _.pick(props, [
    "newTab",
    "accent",
    "download",
    "href",
    "to",
    "active",
    "rel",
    "target",
  ]);
  const [computedLinkProps, renderAs] = getLinkProps(props.url, props.linkType, legacyProps);
  // Render a slightly larger icon for key action buttons.
  const iconSize = props.buttonStyle === ButtonStyles.RAISED ? 18 : 16;

  return (
    <StyledButton
      ref={ref}
      {...computedLinkProps}
      id={props.id}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type} // eslint-disable-line react/button-has-type
      $selected={props.buttonStyle === ButtonStyles.RAISED && props.selected}
      $buttonStyle={props.buttonStyle}
      as={renderAs}
      role={props.role}
    >
      {props.leadingIcon && <props.leadingIcon color={iconColor} size={iconSize} />}
      <span>{props.children}</span>
      {props.trailingIcon && <props.trailingIcon color={iconColor} size={iconSize} />}
    </StyledButton>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  /** force button disabled state */
  disabled: PropTypes.bool,
  /** do not use with href, to, or url */
  onClick: PropTypes.func,
  /* Shown before children inside the button */
  leadingIcon: PropTypes.func,
  /* Shown after children inside the button */
  trailingIcon: PropTypes.func,
  /** do not use with href, to, or url */
  type: PropTypes.string,
  /** the href to use. May be renamed to href in the future if legacy compatibility is removed */
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
  ]),
  /* Filled/Outline button style. If not supplied, then the button is an outline button. */
  buttonStyle: PropTypes.oneOf(Object.values(ButtonStyles)),
  /** Only available for key actions */
  selected: PropTypes.bool,
  /** How the link should be treated for navigation and other best practices purposes */
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /** Aria role */
  role: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  disabled: false,
  selected: false,
  onClick: _.noop,
  leadingIcon: null,
  trailingIcon: null,
  type: "button",
  url: null,
  buttonStyle: ButtonStyles.OUTLINE,
};

Button.displayName = "Button";

export default Button;
export { ButtonStyles };
