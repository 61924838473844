// The commented out colors are left in place to remind us which colors we still need to
// standardize in our theme.
const DataverseColors = {
  branding900: "#B1E2E7",
  branding800: "#A2DCE2",
  branding700: "#92D7DD",
  branding600: "#83D1D8",
  branding500: "#74CBD3",
  branding400: "#64C5CE",
  branding300: "#54BFC9",
  branding200: "#45BAC4",
  branding100: "#3BAFBA",
  d300: "#2B393A",
  d200: "#344546",
  d100: "#3D5152",
  red: "#FFBC96",
  // redHover: "#ff7272",
  // redActive: "#ff7a7a",
  green: "#92CAB7",
  // greenHover: "#33f8a2",
  // greenActive: "#66fab9",
  yellow: "#DFCD6F",
  magenta: "#D593DB",
  indigo: "#557EE7",
};

export { DataverseColors };
